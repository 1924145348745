.picker {
	z-index: 1;
	margin-left: -209px;
	margin-top: 4px;
}

.swatch {
	padding: 5px;
	background: var(--background-color);
	border-radius: 1px;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
	display: flex;
	cursor: pointer;
}

.swatch svg {
	margin-left: 5px;
}

.swatch span {
	font-size: 12px;
	padding-left: 10px;
}
.color {
	width: 36px;
	height: 14px;
	border-radius: 2px;
	background: #333333;
}
