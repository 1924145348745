.mtb-15 {
	margin-top: 15px;
	margin-bottom: 15px;
}

.ml-5 {
	margin-left: 5px;
}

footer {
	flex: 1;
	justify-content: center;
	align-items: center;
	font-family: 'Montserrat';
	display: flex;
	font-size: 12px;
	flex-direction: column;
}

footer a {
	text-decoration: none;
	color: var(--primary);
	font-weight: 600;
}

footer img {
	padding: 0 10px;
}

.ph-badge {
	position: absolute;
	right: 0;
	bottom: 0;
	margin-bottom: 40px;
}

.socialLinks a {
	font-size: 14px;
	margin-right: 10px;
	margin-top: 10px;
}

.socialLinks a:hover svg {
	fill: var(--primary);
}
