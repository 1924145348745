.d-f {
	display: flex;
}

.j-sb {
	justify-content: space-between;
}

.mt-18 {
	margin-top: 18px;
}
.color-picker {
	margin-top: 15px;
}

.logo {
	text-align: center;
	font-family: 'Montserrat';
	font-weight: 600;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 0px;
	margin-bottom: 20px;
}

.options {
	width: 300px;
	height: 100vh;
	padding: 1rem;
	overflow: hidden;
	background: var(--sidebar-bg);
	-webkit-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
		0 2px 4px -1px rgba(0, 0, 0, 0.2);
	box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.options:hover {
	overflow-y: auto;
	overflow-x: hidden;
}

.options h4 {
	font-size: 14px;
	font-family: 'Montserrat';
	font-weight: 600;
}

.options h5 {
	font-size: 12px;
	font-family: 'Montserrat';
	font-weight: 400;
}

input[type='number'] {
	width: 55px;
	height: 25px;
	margin-top: 10px;
	background: var(--background-color);
	color: var(--text-color);
	border: 0px;
	outline: 0;
	padding: 0.3rem 0.4rem;
	border-radius: 0.2rem;
}

input[type='file'] {
	margin-top: 20px;
	margin-left: 40px;
	outline: 0;
}

input[type='url'] {
	width: 95%;
	height: 25px;
	margin-bottom: 10px;
	background: var(--background-color);
	color: var(--text-color);
	border: 0px;
	outline: 0;
	padding: 0.3rem 0.4rem;
	border-radius: 0.2rem;
}

select,
input[type='text'] {
	min-width: 55px;
	height: 25px;
	margin-top: 10px;
	background: var(--background-color);
	color: var(--text-color);
	border: 0px;
	outline: 0;
	padding: 0.3rem 0.4rem;
	border-radius: 0.2rem;
}
